<template>
    <div class="blog-post mb-4">
        <div class="blog-post-img" :class="'blog-' + index">
            <a :href="link" class="p-4" target="_blank">
                <z-picture :img="image" />
                <z-icon name="Arrow" color="#ffffff" class="icon" />
            </a>
        </div>
        <div class="blog-post-text">
            <blog-post-badge :type="type" />
            <div class="title mt-3">{{ title }}</div>
            <div class="author info">{{ author }}</div>
            <div class="date info">{{ date }}</div>
        </div>
    </div>
</template>

<script>
import ZIcon from "./ZIcon.vue";
import BlogPostBadge from "./BlogPostBadge.vue";
import ZPicture from "./ZPicture.vue";

export default {
    components: {
        ZIcon,
        ZPicture,
        BlogPostBadge
    },
    name: "BlogPost",

    props: {
        index: {
            type: Number
        },
        title: {
            type: String,
            default: "Title"
        },

        author: {
            type: String,
            default: "Team Zubut"
        },

        date: {
            type: String,
            default: "1 de Noviembre de 2021"
        },

        image: {
            type: String,
            default: ""
        },

        type: {
            type: Number,
            default: 1
        },

        link: {
            type: String,
            default: "https://www.zubut.com"
        }
    }
};
</script>

<style lang="scss" scoped>
.blog-1 {
    picture {
        position: absolute;
        top: -30px;
    }
}
.blog-2 {
    picture {
        position: absolute;
        top: -100px;
    }
}
a {
    padding: 0 !important;
}
.info {
    color: $comet;
}
.blog-post {
    max-width: 313px;
}
.blog-post-img {
    border-radius: 8px;
    overflow: hidden;
    width: 313px;
    height: 180px;
    background-color: $comet;

    margin-bottom: 16px;
    position: relative;

    .icon {
        position: absolute;
        border: 2px solid white;
        padding: 2px;
        border-radius: 20px;
        transform: scale(1.8);
        top: 25px;
        right: 25px;
        transition: all 0.2s ease-out;

        &:hover {
            transform: scale(2);
        }
    }
}

.blog-post-text {
    width: 100%;
    height: 100%;

    .title {
        font-weight: bolder;
        font-size: 18px;
        margin-bottom: 16px;
    }

    .author {
        font-size: 14px;
        font-weight: bold;
    }
    .date {
        font-size: 14px;
    }
}
</style>
